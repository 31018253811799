import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TextBoxComponent } from '../controls/text-box/text-box.component';
import { UiStore } from '../../../../core/store/ui.store';
import { SavingButtonComponent } from '../../buttons/saving-button/saving-button.component';
import { FormErrorComponent } from '../form-error/form-error.component';
import { CancelButtonComponent } from '../../buttons/cancel-button/cancel-button.component';
import { NgSelectModule } from '@ng-select/ng-select';

export interface FieldConfig {
  id: string;
  label: string;
  formControlName: string;
  placeholder: string;
  type: string;
  validators: string[];
  options?: { id: number; title: string }[];
}

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    TranslateModule,
    ReactiveFormsModule,
    TextBoxComponent,
    SavingButtonComponent,
    FormErrorComponent,
    SavingButtonComponent,
    CancelButtonComponent,
    NgSelectModule,

  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
})
export class FormComponent implements OnInit{
  @Input() formGroup: FormGroup;
  @Input() fields: any;
  @Output() clickOnRadioButton = new EventEmitter<any>();
  @Output() submitFormRequest = new EventEmitter<any>();
  @Input() formClass: string;
  @Input() isButtonsEnabled: boolean = false;
  @Output() changeToggleButton = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder, public uiStore: UiStore) {}

  ngOnInit(): void {
  }
  clearForm() {
    this.formGroup.reset();
  }

  requestSubmitForm() {
    if (this.formGroup.valid) {
      this.submitFormRequest.emit(this.formGroup.value);
    }
  }
  onToggleChange(event:Event) {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.changeToggleButton.emit(isChecked);
  }

  onClickRadionButton(optionId: number) {    
    this.clickOnRadioButton.emit(optionId);
  }

}
