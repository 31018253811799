<app-form-error></app-form-error>
<form [formGroup]="formGroup" (ngSubmit)="requestSubmitForm()" [class]="formClass">
  @for (field of fields; track $index) {
    @switch (field.type) {
      @case ('textbox') {
        <div class="form-group mb-3">
          <label [for]="field.id">{{ field.label | translate }}</label>
          <input [id]="field.id"   [formControlName]="field.formControlName" type="text" class="form-control"/>
        </div>
      }
      @case ('textarea') {
        <div class="form-group mb-3">
          <label [for]="field.id">{{ field.label | translate }}</label>
          <textarea [id]="field.id"   [formControlName]="field.formControlName" class="form-control"></textarea>
        </div>
      }
      @case ('dropdown') {
        <div class="form-group theme-select mb-3">
          <label [attr.for]="field.id">{{ field.label | translate }}</label>
          <select class="form-control" [id]="field.id" [formControlName]="field.formControlName">
            <option [value]="null" selected>{{field.placeholder}}</option>
            @for (option of field.options; track $index) {
              <option [value]="option.id">{{option.title}}</option>
            }
          </select>
        </div>
      }
     
      @case ('autocomplete-static') {
        @if(field.isShow) {
          <div class="form-group theme-select mb-3">
            <label [attr.for]="field.id">{{ field.label | translate }}</label>
            <ng-select
              [items]="field.options!"
              bindLabel="title"
              bindValue="id"
              [multiple]="field.isMulitple || false"
              [placeholder]=" field.placeholder| translate"
              [clearable]="true"
              [formControlName]="field.formControlName">
            </ng-select>
          </div>
        }
      }

      @case ('radiobutton') {
        <div class="form-group theme-select mb-3">
          <label class="clearfix" for="exampleFormControlSelect1">{{field.label |translate}}</label>
          <div class="clearfix">
            @for (option of field.options; track $index) {
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  [formControlName]="field.formControlName"
                  [id]="field.id +'_'+ option.id"
                  (click)="onClickRadionButton(option.id)"
                  [value]="option.id" />
                <label class="form-check-label" [attr.for]="field.id +'_'+ option.id">{{option.title}}</label>
              </div>
            }
          </div>
        </div>
      }
      @case ('slider') {
        <div class="form-group theme-select mb-3">
          <label [attr.for]="field.id">{{ field.label }}</label>
          <input type="range" class="form-range"
                 [formControlName]="field.formControlName"
                 [attr.id]="field.id"
                 [min]="field.min"
                 [max]="field.max"
                 [step]="field.step">
          <div class="d-flex justify-content-between">
            @for (option of field.options; track $index) {
              <span>{{ option.title }}</span>
            }
          </div>
        </div>
      }
      @case ('toggle') {
        <div class="form-group theme-select mb-4">
          <label [attr.for]="field.id">{{ field.label }}</label>
          <div class="d-flex align-items-center">
            <p class="me-3 mb-0 marked-primary-text">{{field.subLabel}}</p>
            <div class="toggle-btn">
              <input type="checkbox" [id]="field.id" [formControlName]="field.formControlName" />
            </div>
          </div>
        </div>
      }
      @case ('toggle-horizontal') {
        <div class="d-flex align-items-center justify-content-between w-100 mt-4">
          <p class="mb-0 mt-2">{{ field.label }}</p>
          <div class="toggle-btn mt-2">
            <input type="checkbox" [id]="field.id" [formControlName]="field.formControlName" (change)="onToggleChange($event)"/>
          </div>
        </div>

      }
    }
  }
  <button type="submit" style="display: none;" aria-hidden="true"></button>
  @if(isButtonsEnabled){
    <div class="d-flex justify-content-start">
      <app-cancel-button [label]="'Cancel'"></app-cancel-button>
      <app-saving-button
        [savingLabel]="'Updating'"
        [label]="'Update'"
        [isValid]="formGroup.valid">
      </app-saving-button>
    </div>
  }
</form>
